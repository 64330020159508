<template>
  <div class="about-hero">
    <div class="about-hero__inner">
      <h1 class="about-hero__header">मेल्लेख गाउँपालिका</h1>
      <div class="about-hero__province">
        <span class="about-hero__"></span>
        <span>अछाम, प्रदेश नं ७</span>
        <span></span>
      </div>
      <div class="about-hero__info">
        <div class="about-hero-info">
          <div class="about-hero-info__name">जनसंख्या</div>
          <div class="about-hero-info__value">
            {{ englishToNepaliNumber(aboutInfo.population) || "n/a" }}
          </div>
        </div>
        <div class="about-hero-info">
          <div class="about-hero-info__name">वडाहरु</div>
          <div class="about-hero-info__value">
            {{ englishToNepaliNumber(aboutInfo.wards) || "n/a" }}
          </div>
        </div>
        <div class="about-hero-info">
          <div class="about-hero-info__name">परिवारहरु</div>
          <div class="about-hero-info__value">
            {{ englishToNepaliNumber(aboutInfo.families) || "n/a" }}
          </div>
        </div>
        <div class="about-hero-info">
          <div class="about-hero-info__name">क्षेत्रफल (वर्ग किमी)</div>
          <div class="about-hero-info__value">
            {{ englishToNepaliNumber(aboutInfo.area) || "n/a" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { englishToNepaliNumber } from "nepali-number";
export default {
  computed: {
    aboutData() {
      return this.$store.getters.about[0];
    },
    achievements() {
      return this.$store.getters.achievements;
    },
    upcomingPrograms() {
      return this.$store.getters.upcomingPrograms;
    }
  },
  watch: {
    aboutData: {
      handler: "changeAboutInfo",
      immediate: true
    }
  },
  data() {
    return {
      key: 0,
      aboutInfo: {
        population: 0,
        wards: 0,
        families: 0,
        area: 0
      }
    };
  },
  methods: {
    englishToNepaliNumber,
    // isActive(itemName) {
    //   return this.activeItem === itemName;
    // },
    // setActive(item) {
    //   this.activeItem = item;
    // },
    changeAboutInfo: function () {
      let aboutInfo = this.$store.getters.about[0];
      if (!aboutInfo) {
        return;
      }
      this.aboutInfo.population = aboutInfo.population;
      this.aboutInfo.wards = aboutInfo.noofwards;
      this.aboutInfo.families = aboutInfo.nooffamilies;
      this.aboutInfo.area = aboutInfo.area;
    }
  }
};
</script>

<style lang="scss" scoped>
.about-hero {
  height: 927px;
  position: relative;

  @media screen and (max-width: 3199px) {
    height: 400px;
  }

  @media screen and (max-width: 1919px) {
    height: 310px;
  }

  &:before {
    height: 100%;
    background: url("../../assets/mellekh.svg");
    content: "";
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    width: 100%;
    position: absolute;
    z-index: -2;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 192px;

    @media screen and (max-width: 3199px) {
      padding-top: 78px;
    }

    @media screen and (max-width: 1919px) {
      padding-top: 41px;
    }

    &:before {
      background: linear-gradient(269.95deg, #e323ff 0.02%, #7517f8 97.45%);
      opacity: 0.3;
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
  }

  &__header {
    font-size: 160px;
    font-weight: 600;
    margin-bottom: 40px;

    @media screen and (max-width: 3199px) {
      font-size: 68px;
      line-height: 102px;
      margin-bottom: 0;
    }

    @media screen and (max-width: 1919px) {
      font-size: 57px;
      line-height: 85.5px;
    }
  }

  &__province {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 1136px;

    @media screen and (max-width: 3199px) {
      margin-bottom: 15px;
      width: 435px;
    }

    @media screen and (max-width: 1919px) {
      width: 390px;
    }

    & > span:nth-child(1),
    & > span:nth-child(3) {
      width: 380px;
      border: 1px solid $neutrals-white;

      @media screen and (max-width: 3199px) {
        width: 117px;
      }
    }

    & > span:nth-child(2) {
      padding: 16px 57px;
      border: 2px solid $neutrals-white;
      border-radius: 40px;
      font-weight: 500;
      font-size: 32px;
      line-height: 48px;
      color: $neutrals-white;

      @media screen and (max-width: 3199px) {
        padding: 6px 20px;
        /* border: 1px solid $neutrals-white; */
        border-radius: 15px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    width: 1136px;

    @media screen and (max-width: 3199px) {
      width: 435px;
    }

    @media screen and (max-width: 1919px) {
      width: 390px;
    }
  }

  &-info {
    color: #f0f8ff;
    text-align: center;

    &__name {
      font-size: 32px;
      line-height: 48px;
      font-weight: 500;

      @media screen and (max-width: 3199px) {
        font-size: 14px;
        line-height: 21px;
      }
    }

    &__value {
      font-size: 80px;
      line-height: 120px;
      font-weight: bold;

      @media screen and (max-width: 3199px) {
        font-size: 28px;
        line-height: 42px;
      }
    }
  }
}
</style>
